import React, { useState, useEffect } from "react";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorModeValue,
  Icon,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { FaSave, FaLock, FaUser, FaSignOutAlt, FaArrowLeft} from "react-icons/fa";
import { supabase } from "../supabaseClient";

const UserProfile = () => {
  const { token, updateToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (token) {
      setUserData({
        name: token.user.user_metadata?.name || "",
        email: token.user.email || "",
        phone: token.user.user_metadata?.phone || "",
      });
    } else {
      console.log("Token or token.user is undefined");
    }
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const { data, error } = await supabase.auth.updateUser({
        data: {
          name: userData.name,
          phone: userData.phone,
        },
      });

      if (error) {
        throw error;
      }

      // Update the token with the new user metadata
      if (token && token.user) {
        const updatedUser = {
          ...token.user,
          user_metadata: {
            ...token.user.user_metadata,
            name: userData.name,
            phone: userData.phone,
          },
        };
        // You might need to implement a function to update the token in your AuthContext
        updateToken({ ...token, user: updatedUser });
      }

      toast({
        title: "Cambios guardados",
        description: "Tu información personal ha sido actualizada.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating user data:", error);
      toast({
        title: "Error",
        description: "No se pudo actualizar la información. Por favor, intenta de nuevo.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChangePassword = () => {
    // Implement password change logic here
    onClose();
    toast({
      title: "Contraseña actualizada",
      description: "Tu contraseña ha sido cambiada exitosamente.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleLogout = () => {
    // Implement logout logic here
    toast({
      title: "Sesión cerrada",
      description: "Has cerrado sesión exitosamente.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    // Navigate to login page or home page after logout
    // navigate('/login');
  };

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const handleUpdateUser = async (updatedUser) => {
    // Assuming you've updated the user in your backend/database
    updateToken({ user: updatedUser });
  };

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box w={{ base: "100%", md: "25%" }} p={5} bg={bgColor} borderRight="1px solid" borderColor="gray.200">
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box bg="gray.100" p={5} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Gestión de Perfil
            </Heading>
            <Text fontSize="sm">
              1. Edita tu información personal.
              <br />
              2. Cambia tu contraseña regularmente.
              <br />
              3. Gestiona tu suscripción.
              <br />
              4. Cierra sesión de forma segura.
            </Text>
          </Box>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Button
              leftIcon={<Icon as={FaArrowLeft} />}
              onClick={() => navigate(-1)}
              size="md"
            >
              Volver
            </Button>
            <Heading as="h1" size="xl">
              Perfil de Usuario
            </Heading>
            <Box width={24} /> {/* This empty Box helps center the heading */}
          </Flex>
          
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            {/* Personal Information */}
            <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
              <Heading as="h3" size="md" mb={4}>
                Información Personal
              </Heading>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Nombre</FormLabel>
                  <Input name="name" value={userData.name} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Correo Electrónico</FormLabel>
                  <Input name="email" value={userData.email} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Teléfono</FormLabel>
                  <Input name="phone" value={userData.phone} onChange={handleInputChange} />
                </FormControl>
                <Button leftIcon={<Icon as={FaSave} />} colorScheme="blue" onClick={handleSaveChanges}>
                  Guardar Cambios
                </Button>
              </VStack>
            </Box>

            {/* Account Settings */}
            <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
              <Heading as="h3" size="md" mb={4}>
                Configuración de Cuenta
              </Heading>
              <VStack spacing={4}>
                <Button leftIcon={<Icon as={FaLock} />} colorScheme="teal" onClick={onOpen} width="full">
                  Cambiar Contraseña
                </Button>
                <Button leftIcon={<Icon as={FaUser} />} colorScheme="purple" width="full">
                  Gestionar Suscripción
                </Button>
                <Button leftIcon={<Icon as={FaSignOutAlt} />} colorScheme="red" onClick={handleLogout} width="full">
                  Cerrar Sesión
                </Button>
              </VStack>
            </Box>
          </SimpleGrid>
        </VStack>
      </Box>

      {/* Change Password Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cambiar Contraseña</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Contraseña Actual</FormLabel>
                <Input type="password" name="currentPassword" value={passwords.currentPassword} onChange={handlePasswordChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Nueva Contraseña</FormLabel>
                <Input type="password" name="newPassword" value={passwords.newPassword} onChange={handlePasswordChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Confirmar Nueva Contraseña</FormLabel>
                <Input type="password" name="confirmPassword" value={passwords.confirmPassword} onChange={handlePasswordChange} />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleChangePassword}>
              Actualizar Contraseña
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default UserProfile;
