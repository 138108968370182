import React from 'react';
import { useNavigate } from 'react-router-dom';

const DocumentationPage = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      {/* Encabezado */}
      <header style={styles.header}>
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="Medify AI Logo"
          style={styles.logo}
        />
        <nav style={styles.nav}>
          <button style={styles.navButton} onClick={() => navigate('/login')}>
            Iniciar Sesión
          </button>
        </nav>
      </header>

      {/* Sección de Documentación */}
      <section style={styles.documentationSection}>
        <h1 style={styles.title}>Documentación</h1>
        <p style={styles.description}>
          Encuentra toda la información necesaria para utilizar Medify AI de
          manera eficiente.
        </p>

        <div style={styles.documentationGrid}>
          <div style={styles.docCategory}>
            <h2 style={styles.sectionTitle}>Guías de Usuario</h2>
            <ul style={styles.docList}>
              <li>
                <a href="/docs/user-guide" style={styles.docLink}>
                  Guía de Inicio Rápido: Encontrá como utilizar nuestras
                  funcionalidades
                </a>
              </li>
            </ul>
          </div>

          <div style={styles.docCategory}>
            <h2 style={styles.sectionTitle}>FAQ</h2>
            <ul style={styles.docList}>
              <li>
                <a href="/docs/faq" style={styles.docLink}>
                  Preguntas Frecuentes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#F7f7f7',
    fontFamily: '"Montserrat", "Poppins", sans-serif',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
    position: 'relative',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginBottom: '20px',
  },
  nav: {
    display: 'flex',
    gap: '20px',
    position: 'absolute',
    right: '20px',
    top: '0',
  },
  navLink: {
    color: '#0058A3',
    fontSize: '16px',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  navButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0058A3',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  documentationSection: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '1200px',
    marginBottom: '60px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2F2F2F',
    marginBottom: '20px',
  },
  description: {
    fontSize: '18px',
    color: '#2F2F2F',
    marginBottom: '40px',
  },
  documentationGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
  },
  docCategory: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    textAlign: 'left',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2F2F2F',
    marginBottom: '10px',
  },
  docList: {
    listStyleType: 'none',
    padding: '0',
  },
  docLink: {
    textDecoration: 'none',
    color: '#0058A3',
    fontSize: '16px',
    transition: 'color 0.3s ease',
    ':hover': {
      color: '#003366',
    },
  },
};

export default DocumentationPage;
