import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import { useDisclosure } from '@chakra-ui/react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    const storedToken = sessionStorage.getItem('token');
    return storedToken ? JSON.parse(storedToken) : null;
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timeoutRef = useRef(null);

  const INACTIVITY_TIMEOUT = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

  const login = useCallback((newToken) => {
    setToken(newToken);
    sessionStorage.setItem('token', JSON.stringify(newToken));
  }, []);

  const logout = useCallback(async (isIntentional = false) => {
    setToken(null);
    sessionStorage.removeItem('token');
    await supabase.auth.signOut();
    if (!isIntentional) {
      onOpen(); // Open the inactivity alert only for automatic logouts
    }
  }, [onOpen]);

  const resetInactivityTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      logout();
    }, INACTIVITY_TIMEOUT);
  }, [logout]);

  useEffect(() => {
    if (token) {
      resetInactivityTimer();
      window.addEventListener('mousemove', resetInactivityTimer);
      window.addEventListener('keypress', resetInactivityTimer);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keypress', resetInactivityTimer);
    };
  }, [token, resetInactivityTimer]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = sessionStorage.getItem('token');
      if (!storedToken && token) {
        logout();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [token, logout]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('token');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const updateToken = useCallback((updatedData) => {
    setToken((prevToken) => {
      const newToken = { ...prevToken, ...updatedData };
      sessionStorage.setItem('token', JSON.stringify(newToken));
      return newToken;
    });
  }, []);

  return (
    <AuthContext.Provider value={{ token, login, logout, updateToken, isOpen, onClose }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
