import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const InactivityAlert = () => {
  const { isOpen, onClose } = useAuth();
  const navigate = useNavigate();
  const cancelRef = React.useRef();

  const handleClose = () => {
    onClose();
    navigate('/');
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Sesión cerrada por inactividad
          </AlertDialogHeader>

          <AlertDialogBody>
            Su sesión ha sido cerrada debido a inactividad. Por favor, inicie sesión nuevamente.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default InactivityAlert;
