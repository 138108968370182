import React, { useState, useEffect } from 'react';
import questionsFertilidad from '../config/pages/Form.json';
import questionsCardio from '../config/pages/FormsCardio.json';
import questionsGineco from '../config/pages/FormsGineco.json';
import '../styles/pages/Form.css';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const defaultedVariables = [
  'doctorName',
  'doctorId',
  'doctorEmail',
  'specialty',
];

function titleMappingToVariable(title) {
  switch (title) {
    case 'Apellido y Nombre':
      return 'apellidoNombre';
    case 'Fecha de Nacimiento (día/mes/Año)':
      return 'fechaNacimiento';
    case 'Edad':
      return 'edad';
    case 'Dirección Completa':
      return 'direccionCompleta';
    case 'Teléfono celular':
      return 'telefonoCelular';
    case 'Mail':
      return 'mail';
    case 'Ocupación':
      return 'ocupacion';
    case 'Grupo y factor':
      return 'grupoFactor';
    case 'Peso':
      return 'peso';
    case 'Altura':
      return 'altura';
    case 'Nombre del médico/a (NO MODIFICAR CAMPO POR FAVOR)':
      return 'doctorName';
    case 'Email del médico/a (NO MODIFICAR CAMPO POR FAVOR)':
      return 'doctorEmail';
    case 'Especialidad (NO MODIFICAR CAMPO POR FAVOR)':
      return 'specialty';
    case 'Redes Sociales (Instagram, Facebook, Linkedin)':
      return 'redesSociales';
    case 'Medico/a (especificar nombre)':
      return 'medicoRecomendado';
    case 'Infertilidad (Si/No) y (Duración en años)':
      return 'infertilidadDuracion';
    case 'Aborto a repetición (Si/No)':
      return 'abortoRepeticion';
    case 'Preservación de óvulos (Si/No)':
      return 'preservacionOvulos';
    case 'Cirugía reproductiva (Si/No)':
      return 'cirugiaReproductiva';
    case 'Ginecología (Si/No)':
      return 'ginecologia';
    case 'Otros Motivos Consulta':
      return 'otrosMotivoConsulta';
    case 'Diabetes (Si/No)':
      return 'diabetes';
    case 'Hipertensión arterial (Si/No)':
      return 'hipertension';
    case 'Asma / Alergias (Si/No)':
      return 'asmaAlergias';
    case 'Medicación habitual':
      return 'medicacionHabitual';
    case 'Otros Antecedentes Personales':
      return 'otrasMedicaciones';
    case 'Antecedentes Familiares (Si/No) y (Cuales)':
      return 'antecedentesFamiliares';
    case 'Antecedentes quirúrgicos (Si/No) y (Cuales)':
      return 'antecedentesQuirurgicos';
    case 'Menarca (edad de su primera menstruación)':
      return 'menarca';
    case 'Ritmo menstrual (cuánto dura su período y cada cuantos días le viene)':
      return 'ritmoMenstrual';
    case 'Dolor menstrual (Si/No)':
      return 'dolorMenstrual';
    case 'Dolor durante las relaciones sexuales (Si/No)':
      return 'dolorRelacionesSexuales';
    case 'Anticonceptivos - Especificar tipo de método (ej: DIU) y tiempo de uso':
      return 'anticonceptivos';
    case 'Menopausia (Si/No)':
      return 'menopausia';
    case 'Vacuna HPV (Si/No)':
      return 'vacunaHPV';
    case 'Infecciones de transmisión sexual (Si/No)':
      return 'ITS';
    case 'Rubeola (Si/No)':
      return 'rubeola';
    case 'Embarazos previos (Si/No) En caso positivo evolución del mismo':
      return 'embarazosPrevios';
    case 'Abortos (Si/No)':
      return 'abortos';
    case 'Otros Antecedentes Ginecológicos u obstétricos':
      return 'otrosGinecologicos';
    case 'Dieta (tipo y restricciones)':
      return 'dieta';
    case 'Ejercicio (frecuencia y tipo)':
      return 'ejercicio';
    case 'Exposición y cantidad y motivo a sustancias tóxicas (tabaco, alcohol, drogas)':
      return 'exposicionSustanciasToxicas';
    case 'Estrés (nivel del 1-10 y fuentes principales)':
      return 'estres';
    case 'FSH':
      return 'FSH';
    case 'LH':
      return 'LH';
    case 'Estradiol':
      return 'estradiol';
    case 'Hormona Anti – Mulleriana':
      return 'hormonaAntiMulleriana';
    case 'TSH':
      return 'TSH';
    case 'Prolactina':
      return 'prolactina';
    case 'Otros Estudios':
      return 'otrosPerfilHormonal';
    case 'Histerosalpingografía - Cavidad (Normal o especificar)':
      return 'histerosalpingografiacavidad';
    case 'Histerosalpingografía - Trompa derecha (Normal o especificar)':
      return 'histerosalpingografiatrompaDerecha';
    case 'Histerosalpingografía - Trompa izquierda (Normal o especificar)':
      return 'histerosalpingografiatrompaIzquierda';
    case 'Histerosalpingografía -  Prueba de cotte positiva (SI/No)':
      return 'histerosalpingografiacomentariosPruebaCotte';
    case 'Cultivo de Flujo (de ser positivo, especificar germen y tratamiento)':
      return 'cultivoFlujo';
    case 'Ecografía transvaginal (Si/No) - En caso de SI especificar fecha':
      return 'ecografiaTransvaginal';
    case 'Ecografia - Útero (Normal o especificar si tiene pólipos o miomas en el informe)':
      return 'ecografiautero';
    case 'Ecografia - Ovario derecho (Normal o especificar hallazgos)':
      return 'ecografiaovarioDerecho';
    case 'Ecografia - Ovario Izquierdo (Normal o especificar hallazgos)':
      return 'ecografiaovarioIzquierdo';
    case 'Cariotipo (Resultado, fecha y lugar del estudio)':
      return 'cariotipo';
    case 'Otros':
      return 'otrosEstudios';
    case 'Apellido y Nombre - Pareja':
      return 'apellidoNombrePareja';
    case 'Fecha de Nacimiento (día/mes/Año) - Pareja':
      return 'fechaNacimientoPareja';
    case 'Edad - Pareja':
      return 'edadPareja';
    case 'Dirección Completa - Pareja':
      return 'direccionCompletaPareja';
    case 'Teléfono celular y línea - Pareja':
      return 'telefonoCelularPareja';
    case 'Mail - Pareja':
      return 'mailPareja';
    case 'Ocupación - Pareja':
      return 'ocupacionPareja';
    case 'Grupo y factor - Pareja':
      return 'grupoFactorPareja';
    case 'Peso - Pareja':
      return 'pesoPareja';
    case 'Altura - Pareja':
      return 'alturaPareja';
    case 'Diabetes (Si/No) - Pareja':
      return 'diabetesPareja';
    case 'Hipertensión arterial (Si/No) - Pareja':
      return 'hipertensionPareja';
    case 'Asma / Alergias (Si/No) - Pareja':
      return 'asmaAlergiasPareja';
    case 'Medicación habitual - Pareja':
      return 'medicacionHabitualPareja';
    case 'Otras - Pareja':
      return 'otrasMedicacionesPareja';
    case 'Antecedentes Familiares (Si/No) y (Cuales) - Pareja':
      return 'antecedentesFamiliaresPareja';
    case 'Antecedentes quirúrgicos (Si/No) y (Cuales) - Pareja':
      return 'antecedentesQuirurgicosPareja';
    case 'Dieta (tipo y restricciones) - Pareja':
      return 'dietaPareja';
    case 'Ejercicio (frecuencia y tipo) - Pareja':
      return 'ejercicioPareja';
    case 'Exposición y cantidad y motivo a sustancias tóxicas (tabaco, alcohol, drogas) - Pareja':
      return 'exposicionSustanciasToxicasPareja';
    case 'Estrés (nivel del 1-10 y fuentes principales) - Pareja':
      return 'estresPareja';
    case 'A. Espermograma (Si/No), Fecha (anotar último)':
      return 'espermogramaPareja';
    case 'Volumen':
      return 'volumenPareja';
    case 'Cantidad o densidad':
      return 'cantidadDensidadPareja';
    case 'Movilidad o motilidad (Grado A, Grado B, Grado C)':
      return 'movilidadMotilidadPareja';
    case 'Morfología':
      return 'morfologiaPareja';
    case 'B. Tunel':
      return 'tunelPareja';
    case 'C. Cariotipo - Pareja':
      return 'cariotipoPareja';
    case 'Otros - Pareja':
      return 'otrosPareja';
    case '1. Tipo de tratamiento realizado (Inseminación/ FIV / ICSI / Ovodonación, Transferencia de embriones criopreservados, Preservación de gametos)':
      return 'tratamiento1Tipo';
    case '1. Fecha del tratamiento':
      return 'tratamiento1Fecha';
    case '1. Numero de óvulos y embriones obtenidos':
      return 'tratamiento1OvulosEmbriones';
    case '1. Congelo embriones/Ovulos (Especificar cantidad)':
      return 'tratamiento1CongeloEmbriones';
    case '1. Transferencia (Si/No) (de no haber transferido, detallar motivo)':
      return 'tratamiento1Transferencia';
    case '1. PGT (Si/No) (en caso de Sí resultado)':
      return 'tratamiento1PGT';
    case '1. Resultados (embarazos, abortos, nacimientos)':
      return 'tratamiento1Resultados';
    case '1. Medicaciones usadas':
      return 'tratamiento1Medicaciones';
    case '1. Efectos secundarios o complicaciones':
      return 'tratamiento1EfectosSecundarios';
    case '2. Tipo de tratamiento realizado (Inseminación/ FIV / ICSI / Ovodonación, Transferencia de embriones criopreservados, Preservación de gametos) (Tratamiento 2)':
      return 'tratamiento2Tipo';
    case '2. Fecha del tratamiento (Tratamiento 2)':
      return 'tratamiento2Fecha';
    case '2. Numero de óvulos y embriones obtenidos (Tratamiento 2)':
      return 'tratamiento2OvulosEmbriones';
    case '2. Congelo embriones/Ovulos (Especificar cantidad) (Tratamiento 2)':
      return 'tratamiento2CongeloEmbriones';
    case '2. Transferencia (Si/No) (de no haber transferido, detallar motivo) (Tratamiento 2)':
      return 'tratamiento2Transferencia';
    case '2. PGT (Si/No) (en caso de Sí resultado) (Tratamiento 2)':
      return 'tratamiento2PGT';
    case '2. Resultados (embarazos, abortos, nacimientos) (Tratamiento 2)':
      return 'tratamiento2Resultados';
    case '2. Medicaciones usadas (Tratamiento 2)':
      return 'tratamiento2Medicaciones';
    case '2. Efectos secundarios o complicaciones (Tratamiento 2)':
      return 'tratamiento2EfectosSecundarios';
    case '3. Tipo de tratamiento realizado (Inseminación/ FIV / ICSI / Ovodonación, Transferencia de embriones criopreservados, Preservación de gametos) (Tratamiento 3)':
      return 'tratamiento3Tipo';
    case '3. Fecha del tratamiento (Tratamiento 3)':
      return 'tratamiento3Fecha';
    case '3. Numero de óvulos y embriones obtenidos (Tratamiento 3)':
      return 'tratamiento3OvulosEmbriones';
    case '3. Congelo embriones/Ovulos (Especificar cantidad) (Tratamiento 3)':
      return 'tratamiento3CongeloEmbriones';
    case '3. Transferencia (Si/No) (de no haber transferido, detallar motivo) (Tratamiento 3)':
      return 'tratamiento3Transferencia';
    case '3. PGT (Si/No) (en caso de Sí resultado) (Tratamiento 3)':
      return 'tratamiento3PGT';
    case '3. Resultados (embarazos, abortos, nacimientos) (Tratamiento 3)':
      return 'tratamiento3Resultados';
    case '3. Medicaciones usadas (Tratamiento 3)':
      return 'tratamiento3Medicaciones';
    case '3. Efectos secundarios o complicaciones (Tratamiento 3)':
      return 'tratamiento3EfectosSecundarios';
    case '4. Tipo de tratamiento realizado (Inseminación/ FIV / ICSI / Ovodonación, Transferencia de embriones criopreservados, Preservación de gametos) (Tratamiento 4)':
      return 'tratamiento4Tipo';
    case '4. Fecha del tratamiento (Tratamiento 4)':
      return 'tratamiento4Fecha';
    case '4. Numero de óvulos y embriones obtenidos (Tratamiento 4)':
      return 'tratamiento4OvulosEmbriones';
    case '4. Congelo embriones/Ovulos (Especificar cantidad) (Tratamiento 4)':
      return 'tratamiento4CongeloEmbriones';
    case '4. Transferencia (Si/No) (de no haber transferido, detallar motivo) (Tratamiento 4)':
      return 'tratamiento4Transferencia';
    case '4. PGT (Si/No) (en caso de Sí resultado) (Tratamiento 4)':
      return 'tratamiento4PGT';
    case '4. Resultados (embarazos, abortos, nacimientos) (Tratamiento 4)':
      return 'tratamiento4Resultados';
    case '4. Medicaciones usadas (Tratamiento 4)':
      return 'tratamiento4Medicaciones';
    case '4. Efectos secundarios o complicaciones (Tratamiento 4)':
      return 'tratamiento4EfectosSecundarios';
    case '5. Tipo de tratamiento realizado (Inseminación/ FIV / ICSI / Ovodonación, Transferencia de embriones criopreservados, Preservación de gametos) (Tratamiento 5)':
      return 'tratamiento5Tipo';
    case '5. Fecha del tratamiento (Tratamiento 5)':
      return 'tratamiento5Fecha';
    case '5. Numero de óvulos y embriones obtenidos (Tratamiento 5)':
      return 'tratamiento5OvulosEmbriones';
    case '5. Congelo embriones/Ovulos (Especificar cantidad) (Tratamiento 5)':
      return 'tratamiento5CongeloEmbriones';
    case '5. Transferencia (Si/No) (de no haber transferido, detallar motivo) (Tratamiento 5)':
      return 'tratamiento5Transferencia';
    case '5. PGT (Si/No) (en caso de Sí resultado) (Tratamiento 5)':
      return 'tratamiento5PGT';
    case '5. Resultados (embarazos, abortos, nacimientos) (Tratamiento 5)':
      return 'tratamiento5Resultados';
    case '5. Medicaciones usadas (Tratamiento 5)':
      return 'tratamiento5Medicaciones';
    case '5. Efectos secundarios o complicaciones (Tratamiento 5)':
      return 'tratamiento5EfectosSecundarios';
    case 'Alguno otro comentario':
      return 'comentariosFinales';
    // Añade los casos para otros campos
    default:
      return title; // Usar el título por defecto si no está en los casos
  }
}

function titleMappingToVariableCardio(title) {
  switch (title) {
    case 'Apellido y Nombre':
      return 'apellidoNombre';
    case 'Fecha de Nacimiento (día/mes/Año)':
      return 'fechaNacimiento';
    case 'Edad':
      return 'edad';
    case 'Dirección Completa':
      return 'direccionCompleta';
    case 'Teléfono celular':
      return 'telefonoCelular';
    case 'Mail':
      return 'mail';
    case 'Ocupación':
      return 'ocupacion';
    case 'Grupo y factor':
      return 'grupoFactor';
    case 'Peso':
      return 'peso';
    case 'Altura':
      return 'altura';
    case 'Nombre del médico/a (Campo Oculto)':
      return 'doctorName';
    case 'Email del médico/a (Campo Oculto)':
      return 'doctorEmail';
    case 'Especialidad (Campo Oculto)':
      return 'specialty';
    case 'Redes Sociales (Instagram, Facebook, Linkedin)':
      return 'redesSociales';
    case 'Medico/a (especificar nombre)':
      return 'medicoRecomendado';
    case 'Dolor de Pecho':
      return 'dolorPecho';
    case 'Falta de Aire (Si/No)':
      return 'faltaAire';
    case 'Palpitaciones (Si/No)':
      return 'palpitaciones';
    case 'Hipertensión Arterial o Presión Alta (Si/No)':
      return 'hipertensionArterial';
    case 'Otros Motivos Consulta':
      return 'otrosMotivoConsulta';
    case 'Diabetes (Si/No)':
      return 'diabetes';
    case 'Hipertensión arterial (Si/No) y duración en años':
      return 'hipertensionArterial';
    case 'Colesterol Alto (Si/No)':
      return 'colesterolAlto';
    case 'Infarto de Miocardio Previo (Si/No)':
      return 'infartoMiocardio';
    case 'Enfermedad Coronaria Conocida (Si/No)':
      return 'enfermedadCoronaria';
    case 'Insuficiencia Cardiaca (Si/No)':
      return 'insuficienciaCardiaca';
    case 'Otros Antecedentes Personales':
      return 'otrosAntecedentes';
    case 'Medicación Habitual (lista completa con dosis y frecuencia)':
      return 'medicacionHabitual';
    case 'Toma Aspirina (Si/No)':
      return 'tomaAspirina';
    case 'Alergias a medicamentos (Si/No, ¿Cuáles?)':
      return 'alergiasMedicamentos';
    case 'Otras alergias (Si/No, ¿Cuáles?)':
      return 'otrasAlergias';
    case 'Antecedentes de hospitalizaciones recientes (Sí/No, ¿Cuándo y por qué motivo?)':
      return 'antecedentesHospitalizaciones';
    case 'Antecedentes Familiares (Enfermedades cardíacas (Si/No, ¿Cuáles?) - Diabetes (Si/No) - Hipertensión arterial (Si/No) - Colesterol alto (Si/No) - Otras enfermedades relevantes (Si/No, ¿Cuáles?))':
      return 'antecedentesFamiliares';
    case 'Antecedentes quirúrgicos (Si/No) y (Cuales)':
      return 'antecedentesQuirurgicos';
    case 'Menarca (edad de su primera menstruación)':
      return 'menarca';
    case 'Embarazos previos (Si/No) En caso positivo evolución del mismo':
      return 'embarazosPrevios';
    case 'Hipertensión del Embarazo (Si/No)':
      return 'hipertensionEmbarazo';
    case 'Diabetes del Embarazo (Si/No)':
      return 'diabetesEmbarazo';
    case 'Abortos (Si/No)':
      return 'abortos';
    case 'Menopausia (Si/No) - Si la respuesta es Sí, ¿a qué edad? - ¿Tuvo Sintomas Asociados?':
      return 'menopausia';
    case 'Dieta (tipo y restricciones)':
      return 'dieta';
    case 'Ejercicio (frecuencia y tipo)':
      return 'ejercicio';
    case '¿Ha fumado alguna vez? (Si/No) - Si su respuesta anterior fue si, ¿Fuma actualmente? - Si fuma actualmente, ¿cuántos cigarrillos fuma por día y desde hace cuánto tiempo fuma [Número de años]?':
      return 'antecedentesFuma';
    case '¿¿Ha intentado dejar de fumar alguna vez? (Si/No) - Si ya no fuma, ¿cuándo dejó de fumar? [Fecha o número de años desde que dejó de fumar]?':
      return 'antecedentesFuma2';
    case 'Estrés (nivel del 1-10 y fuentes principales)':
      return 'estres';
    case 'Consumo de alcohol (frecuencia y cantidad)':
      return 'consumoAlcohol';
    case 'Colesterol Total':
      return 'colesterolTotal';
    case 'HDL':
      return 'HDL';
    case 'LDL':
      return 'LDL';
    case 'Trigliceridos':
      return 'trigliceridos';
    case 'Lipoproteina (a)':
      return 'lipoproteinaA';
    case 'Glucemia':
      return 'glucemia';
    case 'Hemoglobina Glicosada':
      return 'hmoglobinaGlicosada';
    case 'DDVI: Diámetro Diastólico del Ventrículo Izquierdo':
      return 'diametroDiastolicoVentriculoIzquierdo';
    case 'DSVI: Diámetro Sistólico del Ventrículo Izquierdo':
      return 'diametroSistolicoVentriculoIzquierdo';
    case 'FEY: Fracción de Eyección del Ventrículo Izquierdo':
      return 'fraccionEyeccionVentriculoIzquierdo';
    case 'AI: Tamaño Aurícula Izquierda':
      return 'tamañoAuriculaIzquierda';
    case 'Holter (fecha y resultados)':
      return 'holter';
    case 'Otros Estudios':
      return 'otrosEstudios';
    case 'Alguno otro comentario':
      return 'comentariosFinales';
    // Añade los casos para otros campos
    default:
      return title; // Usar el título por defecto si no está en los casos
  }
}

function titleMappingToVariableGineco(title) {
  switch (title) {
    case 'Apellido y Nombre':
      return 'apellidoNombre';
    case 'Fecha de Nacimiento (día/mes/Año)':
      return 'fechaNacimiento';
    case 'Edad':
      return 'edad';
    case 'Dirección Completa':
      return 'direccionCompleta';
    case 'Teléfono celular':
      return 'telefonoCelular';
    case 'Mail':
      return 'mail';
    case 'Ocupación':
      return 'ocupacion';
    case 'Grupo y factor':
      return 'grupoFactor';
    case 'Peso':
      return 'peso';
    case 'Altura':
      return 'altura';
    case 'Nombre del médico/a (Campo Oculto)':
      return 'doctorName';
    case 'Email del médico/a (Campo Oculto)':
      return 'doctorEmail';
    case 'Especialidad (Campo Oculto)':
      return 'specialty';
    case 'Redes Sociales (Instagram, Facebook, Linkedin)':
      return 'redesSociales';
    case 'Medico/a (especificar nombre)':
      return 'medicoRecomendado';
    case 'Control de rutina o chequeo ginecológico (Sí/No) - Describir en detalle':
      return 'rutinaChequeoGinecologico';
    case 'Problemas menstruales (Si/No) - (Sangrado abundante, Menstruación irregular, Ausencia de menstruación, Sangrado entre períodos, Sangrado postmenopáusico, etc) – Describir en detalle':
      return 'problemasMenstruales';
    case 'Dolor pélvico (Sí/No) – (Dolor agudo o crónico, Dolor en las relaciones, Dolor durante la menstruación, Dolor relacionado con la ovulación, etc) - Describir en detalle':
      return 'dolorPelvico';
    case 'Sangrado vaginal anormal (Sí/No) – (Sangrado fuera de la menstruación, Sangrado durante o después de las relaciones sexuales o sangrado irregular sin ningún patrón) - Describir en detalle':
      return 'sangradoVaginalAnormal';
    case 'Secreción vaginal anormal (Sí/No) – (Flujo con olor, color, o consistencia inusual) - Describir en detalle':
      return 'secrecionVaginalAnormal';
    case 'Infecciones recurrentes (Sí/No) – (Infecciones Urinarias o Vaginales) - Describir en detalle':
      return 'infeccionesRecurrentes';
    case 'Problemas urinarios (Sí/No) - Describir en detalle':
      return 'problemasUrinarios';
    case 'Problemas relacionados con anticonceptivos (Sí/No) – (Dificultades para tolerar, pérdidas escasas durante el ciclo, Cambio o colocación, etc) - Describir en detalle':
      return 'problemasAnticonceptivos';
    case 'Problemas Mamarios (Sí/No): - Describir en detalle':
      return 'problemasMamarios';
    case 'Planificación familiar / deseo de concebir (Sí/No) – (Consulta preconcepcional o Asesoramiento de fertilidad) - Describir en detalle':
      return 'planificacionFamiliar';
    case 'Menopausia o síntomas relacionados (Sí/No) – (Sofocos, Sudores, Sequedad Vaginal, Cambios de animo, etc) - Describir en detalle':
      return 'menopausiaSimptomasRelacionados';
    case 'Otros Motivos Consulta':
      return 'otrosMotivoConsulta';
    case 'Diabetes (Si/No)':
      return 'diabetes';
    case 'Hipertensión arterial (Si/No)':
      return 'hipertension';
    case 'Asma / Alergias (Si/No)':
      return 'asmaAlergias';
    case 'Medicación habitual':
      return 'medicacionHabitual';
    case 'Otros Antecedentes Personales':
      return 'otrasMedicaciones';
    case 'Antecedentes Familiares (Si/No) y (Cuales)':
      return 'antecedentesFamiliares';
    case 'Antecedentes quirúrgicos (Si/No) y (Cuales)':
      return 'antecedentesQuirurgicos';
    case 'Menarca (edad de su primera menstruación)':
      return 'menarca';
    case 'Ritmo menstrual (cuánto dura su período y cada cuantos días le viene)':
      return 'ritmoMenstrual';
    case 'Dolor menstrual (Si/No)':
      return 'dolorMenstrual';
    case 'Dolor durante las relaciones sexuales (Si/No)':
      return 'dolorRelacionesSexuales';
    case 'Anticonceptivos - Especificar tipo de método (ej: DIU) y tiempo de uso':
      return 'anticonceptivos';
    case 'Menopausia (Si/No)':
      return 'menopausia';
    case 'Vacuna HPV (Si/No)':
      return 'vacunaHPV';
    case 'Infecciones de transmisión sexual (Si/No)':
      return 'ITS';
    case 'Rubeola (Si/No)':
      return 'rubeola';
    case 'Embarazos previos (Si/No) En caso positivo evolución del mismo':
      return 'embarazosPrevios';
    case 'Abortos (Si/No)':
      return 'abortos';
    case 'Otros Antecedentes Ginecológicos u obstétricos':
      return 'otrosGinecologicos';
    case 'Dieta (tipo y restricciones)':
      return 'dieta';
    case 'Ejercicio (frecuencia y tipo)':
      return 'ejercicio';
    case 'Exposición y cantidad y motivo a sustancias tóxicas (tabaco, alcohol, drogas)':
      return 'exposicionSustanciasToxicas';
    case 'Estrés (nivel del 1-10 y fuentes principales)':
      return 'estres';
    case 'FSH':
      return 'FSH';
    case 'LH':
      return 'LH';
    case 'Estradiol':
      return 'estradiol';
    case 'Hormona Anti – Mulleriana':
      return 'hormonaAntiMulleriana';
    case 'TSH':
      return 'TSH';
    case 'Prolactina':
      return 'prolactina';
    case 'Otros Estudios':
      return 'otrosPerfilHormonal';
    case 'Ecografía transvaginal (Si/No) - En caso de SI especificar fecha':
      return 'ecografiaTransvaginal';
    case 'Ecografia - Útero (Normal o especificar si tiene pólipos o miomas en el informe)':
      return 'ecografiaUtero';
    case 'Ecografia - Ovario derecho (Normal o especificar hallazgos)':
      return 'ecografiaovarioDerecho';
    case 'Ecografia - Ovario Izquierdo (Normal o especificar hallazgos)':
      return 'ecografiaovarioIzquierdo';
    case 'Contaje de Folículos Antrales (Especificar cantidad)':
      return 'contajeFoliculosAntrales';
    case 'Papanicolaou (Sí/No): (Fecha y resultados):':
      return 'papanicolaou';
    case 'Colposcopía (Sí/No): (Fecha y resultados):':
      return 'colposcopia';
    case 'Histeroscopía (Sí/No): (Fecha y resultados):':
      return 'histeroscopia';
    case 'Resonancia magnética pélvica (Sí/No): (Fecha y resultados):':
      return 'resonanciaMagneticaPelvica';
    case 'Ecografía Mamaria (Sí/No): (Fecha y resultados):':
      return 'ecografiaMamaria';
    case 'Mamografía (Sí/No): (Fecha y resultados):':
      return 'mamografia';
    case 'Histerosalpingografía (Si/No) - En caso de SI especificar fecha':
      return 'histerosalpingografia';
    case 'Histerosalpingografía - Cavidad (Normal o especificar)':
      return 'histerosalpingografiaCavidad';
    case 'Histerosalpingografía - Trompa derecha (Normal o especificar)':
      return 'histerosalpingografiatrompaDerecha';
    case 'Histerosalpingografía - Trompa izquierda (Normal o especificar)':
      return 'histerosalpingografiatrompaIzquierda';
    case 'Histerosalpingografía -  Prueba de cotte positiva (SI/No)':
      return 'histerosalpingografiacomentariosPruebaCotte';
    case 'Cultivo de Flujo (Si/No): (En caso de ser positivo especificar germen):':
      return 'cultivoFlujo';
    case 'Cariotipo (Resultado, fecha y lugar del estudio)':
      return 'cariotipo';
    // Añade los casos para otros campos
    default:
      return title; // Usar el título por defecto si no está en los casos
  }
}

function isDefaulted(variableName) {
  return defaultedVariables.includes(variableName);
}

function Form() {
  const [formData, setFormData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [currentSpecialty, setCurrentSpecialty] = useState('');
  const navigate = useNavigate();
  const { specialty } = useParams();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialFormData = {};

    // Inicializar formData con valores de la URL si están presentes
    defaultedVariables.forEach(name => {
      const valueFromUrl = params.get(name);
      initialFormData[name] = valueFromUrl ? valueFromUrl : '';
    });

    setFormData(initialFormData);

    // Set questions based on specialty
    const specialtyFromParams = params.get('specialty') || specialty;

    setCurrentSpecialty(specialtyFromParams);

    const selectedQuestions =
      specialtyFromParams === 'cardiologia'
        ? questionsCardio
        : specialtyFromParams === 'ginecologia'
        ? questionsGineco
        : questionsFertilidad;

    setQuestions(selectedQuestions);
  }, [specialty]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    setLoadingMessage('Enviando Formulario - No actualice la pestaña');

    const dataToSend = {};

    questions.forEach(question => {
      const variableName =
        currentSpecialty === 'cardiologia'
          ? titleMappingToVariableCardio(question.title)
          : currentSpecialty === 'ginecologia'
          ? titleMappingToVariableGineco(question.title)
          : titleMappingToVariable(question.title);
      dataToSend[variableName] = formData[variableName];
    });

    // Ensure doctorId is included in the request
    if (formData.doctorId) {
      dataToSend.doctorId = formData.doctorId;
    }

    // Include other defaulted variables if they exist in formData
    defaultedVariables.forEach(variable => {
      if (formData[variable]) {
        dataToSend[variable] = formData[variable];
      }
    });

    try {
      const API_URL = `${process.env.REACT_APP_DB_MONGO_API}/summary/first-visit/`;

      const response = await axios.post(API_URL, dataToSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setIsSubmitting(false);
        setLoadingMessage('Formulario enviado con éxito. Redirigiendo...');
        setTimeout(() => {
          navigate('/');
        }, 2000); // Wait for 2 seconds before redirecting
      } else {
        setIsSubmitting(false);
        setLoadingMessage('Error al enviar el formulario');
      }
    } catch (error) {
      setIsSubmitting(false);
      setLoadingMessage('Error en la solicitud');
    }
  };

  return (
    <div className="App">
      {isSubmitting ? (
        <div className="loading">
          <h1>
            Formulario de Primera Consulta –{' '}
            {currentSpecialty === 'cardiologia'
              ? 'Cardiología'
              : currentSpecialty === 'ginecologia'
              ? 'Ginecología'
              : 'Fertilidad'}
          </h1>
          <div className="spinner"></div>
          <p>{loadingMessage}</p>
        </div>
      ) : (
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            {questions.map((question, index) => (
              <div
                key={index}
                className="form-section"
                style={
                  isDefaulted(
                    currentSpecialty === 'cardiologia'
                      ? titleMappingToVariableCardio(question.title)
                      : currentSpecialty === 'ginecologia'
                      ? titleMappingToVariableGineco(question.title)
                      : titleMappingToVariable(question.title)
                  )
                    ? { display: 'none' }
                    : {}
                }
              >
                {question.type === 'SECTION_HEADER' && (
                  <h2>{question.title}</h2>
                )}

                {/* Campo de texto simple */}
                {question.type === 'TEXT' && (
                  <div className="form-group">
                    <label>
                      {question.title}{' '}
                      {question.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </label>
                    <input
                      type="text"
                      name={
                        currentSpecialty === 'cardiologia'
                          ? titleMappingToVariableCardio(question.title)
                          : currentSpecialty === 'ginecologia'
                          ? titleMappingToVariableGineco(question.title)
                          : titleMappingToVariable(question.title)
                      }
                      placeholder="Tu respuesta"
                      onChange={handleChange}
                      required={question.required}
                      value={
                        formData[
                          currentSpecialty === 'cardiologia'
                            ? titleMappingToVariableCardio(question.title)
                            : currentSpecialty === 'ginecologia'
                            ? titleMappingToVariableGineco(question.title)
                            : titleMappingToVariable(question.title)
                        ] || ''
                      }
                      disabled={isDefaulted(
                        currentSpecialty === 'cardiologia'
                          ? titleMappingToVariableCardio(question.title)
                          : currentSpecialty === 'ginecologia'
                          ? titleMappingToVariableGineco(question.title)
                          : titleMappingToVariable(question.title)
                      )}
                      style={
                        isDefaulted(
                          currentSpecialty === 'cardiologia'
                            ? titleMappingToVariableCardio(question.title)
                            : currentSpecialty === 'ginecologia'
                            ? titleMappingToVariableGineco(question.title)
                            : titleMappingToVariable(question.title)
                        )
                          ? { display: 'none' }
                          : {}
                      }
                    />
                  </div>
                )}

                {/* Campo de texto largo */}
                {question.type === 'PARAGRAPH_TEXT' && (
                  <div className="form-group">
                    <label>
                      {question.title}{' '}
                      {question.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </label>
                    <textarea
                      name={
                        currentSpecialty === 'cardiologia'
                          ? titleMappingToVariableCardio(question.title)
                          : currentSpecialty == 'ginecologia'
                          ? titleMappingToVariableGineco(question.title)
                          : titleMappingToVariable(question.title)
                      }
                      placeholder="Tu respuesta"
                      onChange={handleChange}
                      required={question.required}
                      value={
                        formData[
                          currentSpecialty === 'cardiologia'
                            ? titleMappingToVariableCardio(question.title)
                            : currentSpecialty === 'ginecologia'
                            ? titleMappingToVariableGineco(question.title)
                            : titleMappingToVariable(question.title)
                        ] || ''
                      }
                    ></textarea>
                  </div>
                )}

                {/* Pregunta de opción múltiple */}
                {question.type === 'MULTIPLE_CHOICE' && (
                  <div className="form-group-multiple">
                    <label>
                      {question.title}{' '}
                      {question.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </label>
                    <div className="choices">
                      {question.choices.map((choice, idx) => (
                        <label key={idx}>
                          <input
                            type="radio"
                            id={`${index}-${idx}`}
                            name={
                              currentSpecialty === 'cardiologia'
                                ? titleMappingToVariableCardio(question.title)
                                : currentSpecialty == 'ginecologia'
                                ? titleMappingToVariableGineco(question.title)
                                : titleMappingToVariable(question.title)
                            }
                            value={choice}
                            onChange={handleChange}
                            required={question.required}
                          />
                          {choice}
                        </label>
                      ))}
                    </div>
                  </div>
                )}

                {/* Campo de selección de fecha */}
                {question.type === 'DATE' && (
                  <div className="form-group">
                    <label>
                      {question.title}{' '}
                      {question.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </label>
                    <input
                      type="date"
                      name={
                        currentSpecialty === 'cardiologia'
                          ? titleMappingToVariableCardio(question.title)
                          : currentSpecialty == 'ginecologia'
                          ? titleMappingToVariableGineco(question.title)
                          : titleMappingToVariable(question.title)
                      }
                      onChange={handleChange}
                      required={question.required}
                      value={
                        formData[
                          currentSpecialty === 'cardiologia'
                            ? titleMappingToVariableCardio(question.title)
                            : currentSpecialty === 'ginecologia'
                            ? titleMappingToVariableGineco(question.title)
                            : titleMappingToVariable(question.title)
                        ] || ''
                      }
                    />
                  </div>
                )}
              </div>
            ))}
            <button type="submit" className="btn-submit">
              Enviar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Form;
