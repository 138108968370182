import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Input,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FaSync, FaRegCopy, FaUserCircle, FaDatabase, FaArrowLeft } from 'react-icons/fa';

const DocumentStatus = {
  READY: 'Resumen generado',
  PENDING: 'Resumen pendiente',
};

const FirstVisitSummaries = () => {
  const { token } = useAuth();
  const { specialty } = useParams();

  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSummary, setEditedSummary] = useState('');
  const [editedPatientName, setEditedPatientName] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  const generateFormUrl = (token, specialty) => {
    const doctorId = encodeURIComponent(token.user.id || '');
    const doctorName = encodeURIComponent(token.user.user_metadata?.name || '');
    const doctorEmail = encodeURIComponent(token.user.email || '');
    const encodedSpecialty = encodeURIComponent(specialty || '');

    return `https://www.medify-ai.com/form?doctorId=${doctorId}&doctorName=${doctorName}&doctorEmail=${doctorEmail}&specialty=${encodedSpecialty}`;
  };

  const showToast = status => {
    if (status === 'success') {
      toast({
        title: 'URL copiada al portapapeles',
        description: 'El link del formulario ha sido copiado exitosamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
    if (status === 'error') {
      toast({
        title: 'Error al copiar URL',
        description:
          'Hubo un problema al copiar el link. Por favor, inténtelo de nuevo.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const handleCopyLink = () => {
    const url = generateFormUrl(token, specialty);
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showToast('success');
      })
      .catch(() => {
        showToast('error');
      });
  };

  const fetchDocuments = async () => {
    if (!token?.user?.email) return;

    const email = encodeURIComponent(token.user.email);
    const db_mongo_url_api = process.env.REACT_APP_DB_MONGO_API;
    const url = `${db_mongo_url_api}/documents/search/${email}?limit=100&offset=0&type_document=first_visit`;

    const fetchPromise = axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(response => {
        setDocuments(response.data);
        return response.data; // This will be passed to the success handler
      });

    toast.promise(fetchPromise, {
      success: {
        title: 'Documentos cargados',
        description: 'Los documentos se han cargado exitosamente.',
        position: 'top',
      },
      error: {
        title: 'Error al cargar',
        description:
          'No se pudieron cargar los documentos. Por favor, intente de nuevo.',
        position: 'top',
      },
      loading: {
        title: 'Cargando documentos',
        description: 'Por favor, espere mientras se cargan los documentos.',
        position: 'top',
      },
    });

    try {
      await fetchPromise;
    } catch (error) {
      console.error('Error fetching documents:', error.message);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [token]);

  const handleRefresh = () => {
    fetchDocuments();
  };

  const handleViewDocument = document => {
    setSelectedDocument({ ...document });
    setEditedSummary(document.summary || '');
    setIsEditing(false);
    onOpen();
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedSummary(selectedDocument?.summary || '');
      setEditedPatientName(selectedDocument?.patient_name || '');
    }
  };

  const handleSummaryChange = event => {
    setEditedSummary(event.target.value);
  };

  const handlePatientNameChange = event => {
    setEditedPatientName(event.target.value);
  };

  const handleSaveChanges = async () => {
    const db_mongo_url_api = process.env.REACT_APP_DB_MONGO_API;

    const url = `${db_mongo_url_api}/documents/?id=${selectedDocument.id}`;

    const updateData = {
      patient_name: editedPatientName,
      summary: editedSummary,
    };

    const updatePromise = axios
      .put(url, updateData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(response => {
        setSelectedDocument(prev => ({
          ...prev,
          patient_name: editedPatientName,
          summary: editedSummary,
        }));
        setIsEditing(false);
        // Update the document in the documents list
        setDocuments(prevDocuments =>
          prevDocuments.map(doc =>
            doc.id === selectedDocument.id
              ? {
                  ...doc,
                  patient_name: editedPatientName,
                  summary: editedSummary,
                }
              : doc
          )
        );
        return response.data;
      });

    toast.promise(updatePromise, {
      success: {
        title: 'Cambios guardados',
        description:
          'Los cambios se han guardado exitosamente en la base de datos.',
        position: 'top',
      },
      error: {
        title: 'Error al guardar',
        description:
          'No se pudieron guardar los cambios. Por favor, intente de nuevo.',
        position: 'top',
      },
      loading: {
        title: 'Guardando cambios',
        description: 'Por favor, espere mientras se guardan los cambios.',
        position: 'top',
      },
    });

    try {
      await updatePromise;
    } catch (error) {}
  };

  const handleDownloadSummary = () => {
    // Implement download summary logic here
    toast({
      title: 'Resumen descargado',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const getDocumentStatusLabel = status => {
    switch (status) {
      case 'ready':
        return DocumentStatus.READY;
      case 'pending':
        return DocumentStatus.PENDING;
      default:
        return status;
    }
  };

  // Custom markdown components with improved styling
  const markdownComponents = {
    ...ChakraUIRenderer(),
    p: props => <Text fontSize="sm" fontFamily="body" mb={2} {...props} />,
    h1: props => (
      <Text
        fontSize="xl"
        fontWeight="bold"
        fontFamily="heading"
        mb={3}
        {...props}
      />
    ),
    h2: props => (
      <Text
        fontSize="lg"
        fontWeight="semibold"
        fontFamily="heading"
        mb={2}
        {...props}
      />
    ),
    h3: props => (
      <Text
        fontSize="md"
        fontWeight="medium"
        fontFamily="heading"
        mb={2}
        {...props}
      />
    ),
    ul: props => (
      <Box as="ul" pl={4} fontSize="sm" fontFamily="body" mb={2} {...props} />
    ),
    ol: props => (
      <Box as="ol" pl={4} fontSize="sm" fontFamily="body" mb={2} {...props} />
    ),
    li: props => (
      <Box as="li" fontSize="sm" fontFamily="body" mb={1} {...props} />
    ),
  };

  const handleBackToDashboard = () => {
    navigate(`/dashboard/${specialty}`);
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box
        w={{ base: '100%', md: '25%' }}
        p={5}
        bg={bgColor}
        borderRight="1px solid"
        borderColor="gray.200"
      >
        <VStack spacing={6} align="stretch">
          <Image
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Medify AI Logo"
            maxW="220px"
            mx="auto"
          />
          <Box bg="gray.100" p={5} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Instrucciones para el Resumen de Primera Visita
            </Heading>
            <Text fontSize="sm">
              1. Generá tu link personalizado del formulario de primera consulta
              para darle acceso a tus pacientes y que ellos puedan completar su
              caso médico antes de asistir a la visita.
              <br />
              <br />
              2. Definí la metodología para enviárselo al paciente. En caso de
              que deba ser completado en el domicilio, puede ser a través de
              whatsapp o e-mail. De lo contrario, se le podrá dar acceso en la
              sala de espera.
              <br />
              <br />
              3. ⁠El día de la consulta, revisa los resúmenes entrantes en la
              sección de "Mis Documentos".
            </Text>
          </Box>
          <Button
            leftIcon={<Icon as={FaSync} />}
            onClick={handleRefresh}
            w="full"
          >
            Actualizar Documentos
          </Button>
          <Button
            leftIcon={<Icon as={FaRegCopy} />}
            onClick={handleCopyLink}
            w="full"
          >
            Copiar link del formulario
          </Button>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Button
              leftIcon={<Icon as={FaArrowLeft} />}
              onClick={handleBackToDashboard}
              size="md"
            >
              Volver
            </Button>
            <Heading as="h1" size="xl">
              Mis Documentos
            </Heading>
            <Box width={24} /> {/* This empty Box helps center the heading */}
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {documents.map(document => (
              <Card key={document.id} variant="outline">
                <CardHeader>
                  <Heading size="md">
                    {document.patient_name || 'Nombre no disponible'}
                  </Heading>
                </CardHeader>
                <CardBody>
                  <Stack spacing={3}>
                    <Text fontSize="sm" color="gray.600">
                      Creado: {new Date(document.created).toLocaleString()}
                    </Text>
                    <Text>
                      <strong>Tipo:</strong> Resumen de Primera Visita
                    </Text>
                    <Text
                      color={
                        document.state === 'ready'
                          ? 'green.500'
                          : document.state === 'pending'
                          ? 'orange.500'
                          : 'gray.500'
                      }
                      fontWeight="bold"
                    >
                      Estado: {getDocumentStatusLabel(document.state)}
                    </Text>
                  </Stack>
                </CardBody>
                <CardFooter>
                  {document.state === 'ready' ? (
                    <Button
                      colorScheme="blue"
                      onClick={() => handleViewDocument(document)}
                    >
                      Visualizar Documento
                    </Button>
                  ) : (
                    <Button isDisabled>Pendiente</Button>
                  )}
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </VStack>
      </Box>

      {/* Drawer for document details */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Detalles del Documento</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Text fontWeight="bold">Nombre del Paciente:</Text>
              {isEditing ? (
                <Input
                  value={editedPatientName}
                  onChange={handlePatientNameChange}
                  placeholder="Nombre del paciente"
                />
              ) : (
                <Text>{selectedDocument?.patient_name || 'No disponible'}</Text>
              )}

              <Text fontWeight="bold">Resumen:</Text>
              {isEditing ? (
                <Textarea
                  value={editedSummary}
                  onChange={handleSummaryChange}
                  minHeight="550px"
                  fontSize="sm"
                  fontFamily="body"
                />
              ) : (
                <Box
                  border="0px"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                  fontSize="sm"
                  fontFamily="body"
                  maxHeight="550px"
                  overflowY="auto"
                >
                  <ReactMarkdown
                    components={markdownComponents}
                    children={selectedDocument?.summary || ''}
                    skipHtml
                  />
                </Box>
              )}

              <Text fontWeight="bold">Fecha de Creación:</Text>
              <Text fontSize="sm">
                {new Date(selectedDocument?.created).toLocaleString()}
              </Text>

              <Button
                onClick={handleEditToggle}
                colorScheme={isEditing ? 'red' : 'blue'}
              >
                {isEditing ? 'Cancelar Edición' : 'Editar Documento'}
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleDownloadSummary}>
              Descargar Resumen
            </Button>
            {isEditing && (
              <Button colorScheme="green" onClick={handleSaveChanges}>
                Guardar Cambios
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default FirstVisitSummaries;