import React, { useState, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import axios from "axios";
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Card,
  CardBody,
  IconButton,
  Tooltip,
  HStack,
  Stack,
  Progress
} from "@chakra-ui/react";
import CustomAudioPlayer from "../components/CustomAudioPlayer"; 
import { FaMicrophone, FaFileUpload, FaPaperPlane, FaUndo, FaArrowLeft } from "react-icons/fa";


const VoiceToText = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { specialty } = useParams();

  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioFile, setAudioFile] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const handleRecordVoice = () => {
    if (!recording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          audioChunksRef.current = [];

          mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorderRef.current.start();
          setRecording(true);

          const intervalId = setInterval(() => {
            setRecordingTime((prevTime) => prevTime + 1);
          }, 1000);

          mediaRecorderRef.current.onstop = () => {
            clearInterval(intervalId);
            const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
            setAudioBlob(audioBlob);
            setAudioFile(audioBlob);
            setRecordingTime(0);
          };
        })
        .catch((error) => console.error("Error accessing microphone", error));
    } else {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setAudioFile(file);
    setAudioBlob(file);
  };

  const prepareChunkInfo = (currentChunk, totalChunks, fileId) => {
    let fileExtension = '.wav';
    if (audioFile && audioFile.name) {
      try {
        const nameParts = audioFile.name.split('.');
        if (nameParts.length > 1) {
          const extractedExtension = '.' + nameParts.pop();
          if (extractedExtension !== '.' && extractedExtension.length <= 5) {
            fileExtension = extractedExtension;
          }
        }
      } catch (error) {
        console.warn('Error extracting file extension:', error.message);
      }
    }
    return {
      chunk_number: currentChunk,
      total_chunks: totalChunks,
      file_id: fileId,
      file_extension: fileExtension
    };
  };

  const uploadChunk = async (chunk, chunkNumber, totalChunks, fileId) => {
    const formData = new FormData();
    formData.append("file", new Blob([chunk]), `chunk${chunkNumber}`);
    
    const chunkInfo = prepareChunkInfo(chunkNumber, totalChunks, fileId);
    formData.append("chunk_info", JSON.stringify(chunkInfo));

    try {
      const voiceToTextUrl = process.env.REACT_APP_VOICE_TO_TEXT_URL;
      const response = await axios.post(`${voiceToTextUrl}/upload-chunk/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading chunk:", error);
      throw error;
    }
  };

  const handleSendFileForSummary = async () => {
    if (!audioFile) {
      alert("Por favor, adjunte un archivo de audio o grabe una consulta médica antes de enviar.");
      return;
    }

    setLoading(true);
    setUploadProgress(0);

    try {
      const CHUNK_SIZE = 1024 * 1024; // 1MB chunks
      const newFileId = Date.now().toString();
      const totalChunks = Math.ceil(audioFile.size / CHUNK_SIZE);

      for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
        const start = (chunkNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, audioFile.size);
        const chunk = audioFile.slice(start, end);

        let result;
        let retries = 0;
        const maxRetries = 3;

        do {
          result = await uploadChunk(chunk, chunkNumber, totalChunks, newFileId);
          retries++;
        } while (
          chunkNumber === totalChunks &&
          result.message !== "File upload complete" &&
          retries < maxRetries
        );

        if (chunkNumber === totalChunks) {
          if (result.message === "File upload complete") {
            setFileId(newFileId);
          } else {
            throw new Error("Failed to upload the last chunk after multiple attempts");
          }
        }

        setUploadProgress((chunkNumber / totalChunks) * 100);
      }

      if (newFileId) {
        const voiceToTextUrl = process.env.REACT_APP_VOICE_TO_TEXT_URL;
        let processResponse;
        let retries = 0;
        const maxRetries = 5;

        while (retries < maxRetries) {
          try {
            processResponse = await axios.post(
              `${voiceToTextUrl}/process_audio/${newFileId}`,
              null,
              {
                params: {
                  doctor_email: token?.user.email || "None",
                  doctor_id: token?.user.id || "None",
                  type_document: "transcript_summary",
                  specialty: specialty || "None",
                },
              }
            );

            if (processResponse.status === 200) {
              onOpen();
              break;
            }
          } catch (error) {
            console.error(`Attempt ${retries + 1} failed:`, error);
          }

          retries++;
          if (retries < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }

        if (retries === maxRetries) {
          throw new Error("Failed to process audio after multiple attempts");
        }
      }
    } catch (error) {
      console.error("Error in handleSendFileForSummary:", error);
      alert("Ha ocurrido un error al procesar el archivo. Por favor, inténtelo de nuevo.");
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const resetSession = () => {
    setRecording(false);
    setRecordingTime(0);
    setAudioFile(null);
    setAudioBlob(null);
    setFileId(null);
    setUploadProgress(0);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    audioChunksRef.current = [];
  };

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box w={{ base: "100%", md: "25%" }} p={5} bg={bgColor} borderRight="1px solid" borderColor="gray.200">
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box bg="gray.100" p={5} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Guía para el Uso del Módulo de Voz a Texto
            </Heading>
            <Text fontSize="sm">
              1. Adjunte el archivo de audio de la consulta o grábela en vivo.
              <br /><br />
              2. Finalizada la carga, oprima el botón "Enviar archivo para resumen" y aguarde su procesamiento.
              <br /><br />
              3. La información será procesada por diferentes modelos de AI para:
              <br />
              a. Convertirse de audio a texto
              <br />
              b. Resumirse en formato historia clínica tradicional.
              <br /><br />
              4. El resumen resultante se mostrará en pantalla para poder copiarse y pegarse al sistema de historia clínica.
            </Text>
          </Box>
          <Tooltip label="Reiniciar sesión">
            <Button
              leftIcon={<Icon as={FaUndo} />}
              onClick={resetSession}
              colorScheme="gray"
              size="sm"
              width="full"
            >
              Reiniciar Sesión
            </Button>
          </Tooltip>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Button
              leftIcon={<Icon as={FaArrowLeft} />}
              onClick={() => navigate(-1)}
              size="md"
            >
              Volver
            </Button>
            <Heading as="h1" size="xl">
              Modulo Transcriptor de Voz a Texto
            </Heading>
            <Box width={24} /> {/* This empty Box helps center the heading */}
          </Flex>

          <Card bg={cardBgColor} shadow="md" borderRadius="lg">
            <CardBody>
              <VStack spacing={6}>
                <HStack spacing={4} width="100%">
                  <Button
                    leftIcon={<Icon as={FaMicrophone} />}
                    onClick={handleRecordVoice}
                    colorScheme="cyan"
                    size="lg"
                    cursor="pointer"
                    height="60px"
                    margin={0}
                    width="100%"
                  >
                    {recording ? "Detener Grabación" : "Grabar Consulta Médica"}
                  </Button>
                  <Button
                    as="label"
                    leftIcon={<Icon as={FaFileUpload} />}
                    colorScheme="cyan"
                    size="lg"
                    cursor="pointer"
                    height="60px"
                    width="100%"
                    margin={0}
                  >
                    Adjuntar Audio de Consulta Médica
                    <Input
                      type="file"
                      accept="audio/*"
                      onChange={handleFileUpload}
                      display="none"
                    />
                  </Button>
                </HStack>

                {recording && (
                  <Text color="red.500" fontWeight="bold" fontSize="lg">
                    Grabando... {Math.floor(recordingTime / 60)}:
                    {(recordingTime % 60).toString().padStart(2, "0")}
                  </Text>
                )}

                {audioBlob && (
                  <Box width="100%">
                    <Heading as="h3" size="md" mb={2}>Audio:</Heading>
                    <CustomAudioPlayer audioBlob={audioBlob} />
                  </Box>
                )}

                <Button
                  leftIcon={<Icon as={FaPaperPlane} />}
                  onClick={handleSendFileForSummary}
                  colorScheme="blue"
                  size="lg"
                  width="100%"
                  isLoading={loading}
                  loadingText="Archivo resumiéndose..."
                  height="60px"
                >
                  Enviar Archivo para Resumen
                </Button>

                {loading && (
                  <Box width="100%">
                    <Text mb={2}>Progreso de carga: {Math.round(uploadProgress)}%</Text>
                    <Progress value={uploadProgress} size="sm" colorScheme="blue" />
                  </Box>
                )}
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Box>

      {/* Modal for summary generated message */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Su resumen está siendo generado</ModalHeader>
          <ModalBody>
            <Text>Encontrará el mismo en la sección "Mis documentos"</Text>
            <Link to={`/my-documents/${specialty}`} style={{ color: "blue", textDecoration: "underline" }}>
              Haga click aquí para acceder a la sección.
            </Link>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default VoiceToText;