// src/SignIn.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../supabaseClient';
import { useAuth } from '../components/AuthContext';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  useToast,
  Container,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { FaHeartbeat, FaFemale, FaBaby } from 'react-icons/fa';

const SignIn = () => {
  const { login } = useAuth();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [loginType, setLoginType] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('fertilidad')) setLoginType('fertilidad');
    else if (path.includes('ginecologia')) setLoginType('ginecologia');
    else if (path.includes('cardiologia')) setLoginType('cardiologia');
  }, [location]);

  function handleInputChange(event) {
    setFormData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data, error } = await supabase.auth.signInWithPassword(formData);
      if (error) throw error;
      login(data);
      navigate(`/dashboard/${loginType || ''}`);
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error de inicio de sesión',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getIcon = () => {
    switch(loginType) {
      case 'fertilidad': return FaBaby;
      case 'ginecologia': return FaFemale;
      case 'cardiologia': return FaHeartbeat;
      default: return FaHeartbeat;
    }
  };

  const getSubtitle = () => {
    switch(loginType) {
      case 'fertilidad': return 'Asistente IA para especialistas en fertilidad';
      case 'ginecologia': return 'Asistente IA para especialistas en ginecología';
      case 'cardiologia': return 'Asistente IA para especialistas en cardiología';
      default: return 'Asistente IA para especialistas médicos';
    }
  };

  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center" bg={bgColor}>
      <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
        <Box
          py="8"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
          bg={cardBgColor}
        >
          <VStack spacing="6" align="stretch">
            <VStack spacing="2" align="center">
              <Icon as={getIcon()} w={12} h={12} color="blue.500" />
              <Heading fontSize="3xl" fontWeight="extrabold">
                Medify AI
              </Heading>
              <Text fontSize="md" color="gray.500">
                {getSubtitle()}
              </Text>
            </VStack>
            <VStack as="form" onSubmit={handleSubmit} spacing="6">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FontAwesomeIcon icon={faUser} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Mail de usuario"
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FontAwesomeIcon icon={faLock} color="gray.300" />
                </InputLeftElement>
                <Input
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  placeholder="Contraseña"
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <Button type="submit" colorScheme="blue" size="lg" fontSize="md" width="full">
                Iniciar Sesión
              </Button>
            </VStack>
          </VStack>
        </Box>
      </Container>
    </Flex>
  );
};

export default SignIn;
