import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Image,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    country: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const getLocation = async () => {
    try {
      const res = await fetch('https://api.db-ip.com/v2/free/self');
      const { countryCode, error } = await res.json();
      if (error) throw new Error(error);
      return countryCode;
    } catch (error) {
      console.error('error getting location from api.db-ip.com:', error.message);
    }
  };

  const handleInputChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const country = await getLocation();
      setFormData(prev => ({ ...prev, country }));

      console.log('Signing up with data:', {
        email: formData.email,
        name: formData.name,
        country: country,
        phone: formData.phone,
      });

      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            name: formData.name,
            country: country,
            phone: formData.phone, 
          }
        }
      });

      if (error) {
        if (error.message.includes("Error sending confirmation email")) {
          console.warn('User created but confirmation email failed to send:', error);
          toast({
            title: "Cuenta creada",
            description: "Su cuenta ha sido creada, pero hubo un problema al enviar el correo de confirmación. Por favor, contacte al soporte.",
            status: "warning",
            duration: 7000,
            isClosable: true,
          });
          return;
        }
        throw error;
      }
      
      console.log('Signup successful:', data);

      toast({
        title: "Cuenta creada",
        description: "Revise su correo electrónico para confirmar su cuenta",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate('/');
      
    } catch (error) {
      console.error('Error signing up:', error);
      console.error('Error details:', error.details);
      toast({
        title: "Error en el registro",
        description: error.message || "Ocurrió un error durante el registro. Por favor, inténtelo de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" minH="100vh" bg={bgColor} alignItems="center" justifyContent="center">
      <Box w={{ base: "90%", md: "400px" }} bg={cardBgColor} p={8} borderRadius="md" boxShadow="lg">
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Heading as="h2" size="lg" textAlign="center">
            Registro de nuevo usuario
          </Heading>
          <form onSubmit={handleSubmit}>
            <VStack spacing={6}>
              <FormControl isRequired>
                <FormLabel>Nombre y Apellido</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Ingrese su nombre completo"
                  size="lg"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Correo Electrónico</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Ingrese su correo electrónico"
                  size="lg"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Contraseña</FormLabel>
                <InputGroup size="lg">
                  <Input
                    pr="4.5rem"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Ingrese su contraseña"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Teléfono</FormLabel>
                <Input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Ingrese su número de teléfono"
                  size="lg"
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" width="full" size="lg" mt={4}>
                Registrar
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
    </Flex>
  );
};

export default SignUp;