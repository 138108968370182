import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faUserMd, faFileMedical, faTasks } from '@fortawesome/free-solid-svg-icons';
import {
  ChakraProvider,
  extendTheme,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Button,
  Link,
  Flex
} from '@chakra-ui/react'


const theme = extendTheme({
  components: {
    Menu: {
      baseStyle: {
        list: {
          bg: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          padding: '10px',
          minWidth: '400px',
        },
        item: {
          fontSize: '18px',
          fontWeight: 'bold',
          _hover: { bg: '#f0f0f0' },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: '5px',
      },
      variants: {
        ghost: {
          color: '#0058A3',
          _hover: { bg: 'transparent', color: '#004080' },
        },
      },
    },
  },
});

const Homepage = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null); // Crear un ref para el pop-up

  const handleLoginFertilidad = () => {
    navigate('/login/fertilidad'); // Redirige a la página de inicio de sesión específica para Fertilidad
  };
  
  const handleLoginCardiologia = () => {
    navigate('/login/cardiologia'); // Redirige a la página de inicio de sesión específica para Cardiología
  };

  const handleLoginGinecologia = () => {
    navigate('/login/ginecologia'); // Redirige a la página de inicio de sesión específica para Ginecología
  };

  const handleRegister = () => {
    navigate('/signup'); // Redirige a la página de registro
  };

  // useEffect para manejar clics fuera del pop-up
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false); // Cierra el pop-up si se hace clic fuera de él
      }
    };

    // Añadir evento de clic cuando el pop-up esté visible
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  return (
    <ChakraProvider theme={theme}>
      <div style={styles.container}>
          {/* Encabezado */}
          <header style={styles.header}>
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" style={styles.logo} />
            <Flex as="nav" align="center" justify="flex-end" gap="20px" style={styles.nav}>
              <Link href="#sobre-medify" style={{ textDecoration: 'none' }}>
                <Button variant="ghost" style={styles.navButton}>
                  Sobre Medify
                </Button>
              </Link>
              <Menu>
                <MenuButton as={Button} variant="ghost" style={styles.navButton}>
                  Iniciar Sesión
                </MenuButton>
                <Portal>
                  <MenuList>
                    <MenuItem onClick={handleLoginFertilidad} style={styles.menuItem('Fertilidad')}>Fertilidad</MenuItem>
                    <MenuItem onClick={handleLoginCardiologia} style={styles.menuItem('Cardiología')}>Cardiología</MenuItem>
                    <MenuItem onClick={handleLoginGinecologia} style={styles.menuItem('Ginecología')}>Ginecología</MenuItem>
                    <MenuItem onClick={handleRegister} style={styles.registerItem}>No tienes una cuenta, ¡regístrate aquí!</MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Flex>
          </header>

          {/* Sección de Servicios */}
          <section style={styles.servicesSection}>
            <h1 style={styles.title}>Nuestros Servicios</h1>
            <p style={styles.description}>
              Descubre cómo Medify AI puede mejorar tu práctica médica con nuestras soluciones de inteligencia artificial.
            </p>
            <div style={styles.servicesButtons}>
              <button style={styles.primaryButton}>Fertilidad</button>
              <button style={styles.secondaryButton}>Cardiología</button>
              <button style={styles.tertiaryButton}>Ginecología</button>
            </div>
          </section>

          {/* Sección de Beneficios */}
          <section style={styles.benefitsSection}>
            <h1 style={styles.title}>Beneficios de Integrar AI en tu Consultorio</h1>
            <p style={styles.description}>
              La inteligencia artificial puede transformar tu práctica médica de muchas maneras. Aquí te mostramos algunos de los beneficios.
            </p>
            <div style={styles.benefitsGrid}>
              <div style={styles.benefitCard}>
                <div style={styles.iconContainer}>
                  <FontAwesomeIcon icon={faTasks} style={styles.icon} />
                </div>
                <h2 style={styles.benefitTitle}>Ahorro de Tiempo en Tareas Administrativas</h2>
                <p style={styles.benefitDescription}>Automatiza la generación de historias clínicas y resúmenes permitiéndote ahorrar hasta un 30% de tu tiempo diario.</p>
              </div>
              <div style={styles.benefitCard}>
                <div style={styles.iconContainer}>
                  <FontAwesomeIcon icon={faUserMd} style={styles.icon} />
                </div>
                <h2 style={styles.benefitTitle}>Mejora la relación médico - paciente</h2>
                <p style={styles.benefitDescription}>Al automatizar tareas repetitivas, podrás invertir tu tiempo en lo que más importa: el cuidado del paciente.</p>
              </div>
              <div style={styles.benefitCard}>
                <div style={styles.iconContainer}>
                  <FontAwesomeIcon icon={faFileMedical} style={styles.icon} />
                </div>
                <h2 style={styles.benefitTitle}>Historias Clínicas más claras y estandarizadas</h2>
                <p style={styles.benefitDescription}>Generá documentación clara y robusta que te permita realizar un mejor seguimiento de pacientes, cometer menor errores y obtener mayor respaldo legal.</p>
              </div>
              <div style={styles.benefitCard}>
                <div style={styles.iconContainer}>
                  <FontAwesomeIcon icon={faHeartbeat} style={styles.icon} />
                </div>
                <h2 style={styles.benefitTitle}>Medicina Personalizada</h2>
                <p style={styles.benefitDescription}>Ofrece tratamientos personalizados basados en datos precisos.</p>
              </div>
            </div>
          </section>

          <section id="sobre-medify" style={styles.summarySection}>
            <h1 style={styles.title}>El Burnout Médico: Un Problema Crítico</h1>
            <p style={styles.description}>
              El burnout médico ha alcanzado niveles críticos a nivel global, afectando a la gran mayoría de los profesionales (desde 30% al 50%) <a href="https://www.cdc.gov/vitalsigns/health-worker-mental-health/index.html#:~:text=The%20study%20showed%20how%20symptoms,compared%20to%2032%25%20in%202018." target="_blank" rel="noopener noreferrer">(1)</a>. 
            </p>
            <p style={styles.description}>
            Este agotamiento está relacionado con una brecha tecnológica en la práctica médica, donde los profesionales dedican entre el 30% y 55% de su tiempo a tareas administrativas como la creación y gestión de registros electrónicos <a href="https://www.medscape.com/slideshow/2020-lifestyle-burnout-6012460#5" target="_blank" rel="noopener noreferrer">(2)</a>. y también está asociado a la constante exposición de los profesionales a decisiones complejas de lidiar con la salud de los pacientes.
            </p>
            <h2 style={styles.subtitle}>Cómo Medify AI Cierra la Brecha</h2>
            <p style={styles.description}>
            La inteligencia artificial (IA) se presenta como una solución revolucionaria para una práctica donde la tecnología aún tiene mucho por avanzar. <a href="https://catalyst.nejm.org/doi/full/10.1056/CAT.23.0404?query=CON&cid=DM2321351_Catalyst_Non_Subscriber&bid=2072618441#sec-2" target="_blank" rel="noopener noreferrer">(3)</a>.
            </p>
            <p style={styles.description}>
              En Medify AI, nos especializamos en cerrar este gap tecnológico, brindando soluciones basadas en inteligencia artificial que automatizan las tareas administrativas y permiten una atención más personalizada y eficiente. Al optimizar los flujos de trabajo, ayudamos a los profesionales a recuperar el tiempo que antes dedicaban a tareas sin valor agregado, para que puedan enfocarse en lo que realmente importa: mejorar la experiencia del paciente y ofrecer un cuidado de calidad.
            </p>
          </section>
    </div>
    </ChakraProvider>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#F7f7f7',
    fontFamily: '"Montserrat", "Poppins", sans-serif',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
    position: 'relative',
  },
  logo: {
    width: '300px',
    height: 'auto',
    marginBottom: '0px',
  },
  nav: {
    display: 'flex',
    gap: '20px',
    position: 'absolute',
    alignItems: 'center',
    right: '20px',
    top: '0',
  },
  navLink: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0058A3',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  navButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0058A3',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  menuItem: (type) => ({
    display: 'block',
    width: '100%',
    padding: '10px',
    marginBottom: '5px',
    backgroundColor: type === 'Fertilidad' ? '#007BFF' : type === 'Cardiología' ? '#00B3D9' : '#FF69B4',
    color: '#FFFFFF',
    borderRadius: '5px',
    fontWeight: 'bold',
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '18px',
  }),
  registerItem: {
    textAlign: 'center',
    padding: '10px',
    marginTop: '10px',
    fontSize: '18px',
    color: '#2F2F2F',
    cursor: 'pointer',
  },
  popup: {
    position: 'absolute',
    top: '60px',
    right: '20px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '10px',
    zIndex: 1000,
    width: '400px',  // Puedes ajustar este valor para cambiar el ancho
    height: 'auto',
  },
  popupButton: {
    display: 'block',
    width: '100%',
    padding: '10px',
    marginBottom: '5px',
    backgroundColor: '#007BFF',
    color: '#FFFFFF',
    borderRadius: '5px',
    fontWeight: 'bold',
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '18px',
  },
  registerText: {
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '18px',
    color: '#2F2F2F',
  },
  registerLink: {
    color: '#007BFF',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  servicesSection: {
    textAlign: 'center',
    marginBottom: '60px',
  },
  servicesButtons: {
    display: 'flex',
    justifyContent: 'center', // Centramos los botones en el contenedor
    gap: '20px', // Espacio entre los botones
    flexWrap: 'wrap', // Permite que los botones se envuelvan si es necesario
    width: '100%', // Asegura que el contenedor ocupe todo el ancho
  },
  primaryButton: {
    backgroundColor: '#0058A3',
    color: '#FFFFFF',
    padding: '15px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '250px', // Ancho fijo para todos los botones
    maxWidth: '300px', // Máximo ancho en pantallas más grandes
    minWidth: '150px', // Mínimo ancho para pantallas más pequeñas
    orderBottom: '0px solid transparent'
  },
  secondaryButton: {
    backgroundColor: '#00B3D9',
    color: '#FFFFFF',
    padding: '15px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '250px', // Ancho fijo para todos los botones
    maxWidth: '300px', // Máximo ancho en pantallas más grandes
    minWidth: '150px', // Mínimo ancho para pantallas más pequeñas
    orderBottom: '0px solid transparent'

  },
  tertiaryButton: {
    backgroundColor: '#FF69B4',
    color: '#FFFFFF',
    padding: '15px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '250px', // Ancho fijo para todos los botones
    maxWidth: '300px', // Máximo ancho en pantallas más grandes
    minWidth: '150px', // Mínimo ancho para pantallas más pequeñas
  },
  primaryButtonHover: {
    transform: 'translateY(-2px)',
    backgroundColor: '#004080',
  },
  secondaryButtonHover: {
    transform: 'translateY(-2px)',
    backgroundColor: '#008fb0',
  },
  tertiaryButtonHover: {
    transform: 'translateY(-2px)',
    backgroundColor: '#FF4587',
  },
  benefitsSection: {
    textAlign: 'center',
    flex: 1,
  },
  benefitsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
  },
  benefitCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    textAlign: 'center',
    orderBottom: '0px solid transparent'

  },
  iconContainer: {
    marginBottom: '15px',
    orderBottom: '0px solid transparent'

  },
  icon: {
    fontSize: '50px',
    color: '#0058A3',
  },
  benefitTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2F2F2F',
    marginBottom: '10px',
  },
  benefitDescription: {
    fontSize: '16px',
    color: '#2F2F2F',
  },
  summarySection: {
    backgroundColor: '#F7f7f7',  
    padding: '40px',
    borderRadius: '8px',
    marginTop: '60px',  
    width: '100%',
    maxWidth: '1300px', // Aseguramos que el ancho máximo sea similar al de las tarjetas de beneficios
    margin: '40px auto',  // Centramos el bloque horizontalmente
    padding: '30px',
    orderBottom: '0px solid transparent'

  },
  title: {
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2F2F2F',
    marginBottom: '20px',
    borderBottom: '0px solid transparent'
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: '600',
    color: '#2F2F2F',
    marginTop: '40px',
    marginBottom: '20px',
    borderBottom: '0px solid transparent'

  },
  description: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#2F2F2F',
    lineHeight: '1.6',
    marginBottom: '20px',
    borderBottom: '0px solid transparent'
  },
  link: {
    color: '#0058A3',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
    // Media queries para pantallas pequeñas
    '@media (max-width: 768px)': {
      header: {
        flexDirection: 'column',
      },
      logo: {
        width: '150px',
      },
      nav: {
        flexDirection: 'column',
        gap: '10px',
      },
      servicesButtons: {
        flexDirection: 'column',
        width: '100%',
      },
      primaryButton: {
        width: '100%',
      },
      benefitsGrid: {
        gridTemplateColumns: '1fr',
      },
      summarySection: {
        textAlign: 'center',
        backgroundColor: '#F9F9F9',  
        padding: '40px',
        borderRadius: '8px',
        marginTop: '60px',  
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '1100px', // Mismo ancho máximo que las tarjetas de beneficios
        margin: '40px auto',  // Centrado en la página
        padding: '30px',
      },
      title: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#2F2F2F',
        marginBottom: '20px',
      },
      subtitle: {
        fontSize: '28px',
        fontWeight: '600',
        color: '#2F2F2F',
        marginTop: '40px',
        marginBottom: '20px',
      },
      description: {
        fontSize: '18px',
        color: '#2F2F2F',
        lineHeight: '1.6',
        marginBottom: '20px',
      },
      link: {
        color: '#0058A3',
        textDecoration: 'none',
        fontWeight: 'bold',
      },
    },
  };

export default Homepage;