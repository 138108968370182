import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  Select,
  Input,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCalculator, FaArrowLeft } from 'react-icons/fa';

const CustomSelect = ({ label, value, options, onChange }) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Select value={value} onChange={onChange}>
      <option value="">Seleccione</option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </FormControl>
);

const CustomInput = ({
  label,
  value,
  onChange,
  placeholder,
  type = 'number',
}) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </FormControl>
);

const ResultItem = ({ label, value }) => (
  <Box>
    <Text fontWeight="bold">{label}:</Text>
    <Text>{value}</Text>
  </Box>
);

const CardiovascularRiskCalculator = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    gender: '',
    age: '',
    hdl: '',
    totalCholesterol: '',
    systolicPressure: '',
    isTreated: 'false',
    isSmoker: 'false',
    hasDiabetes: 'false',
  });
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  const validateInputs = () => {
    return (
      formData.gender &&
      formData.age &&
      formData.hdl &&
      formData.totalCholesterol &&
      formData.systolicPressure
    );
  };

  const calculatePoints = () => {
    let points = 0;

    // Age points
    const ageValue = parseInt(formData.age);
    if (formData.gender === 'male') {
      if (ageValue >= 30 && ageValue <= 34) points += 0;
      else if (ageValue >= 35 && ageValue <= 39) points += 2;
      else if (ageValue >= 40 && ageValue <= 44) points += 5;
      else if (ageValue >= 45 && ageValue <= 49) points += 6;
      else if (ageValue >= 50 && ageValue <= 54) points += 7;
      else if (ageValue >= 55 && ageValue <= 59) points += 10;
      else if (ageValue >= 60 && ageValue <= 64) points += 11;
      else if (ageValue >= 65 && ageValue <= 69) points += 12;
      else if (ageValue >= 70 && ageValue <= 74) points += 14;
      else if (ageValue >= 75) points += 15;
    } else {
      if (ageValue >= 30 && ageValue <= 34) points += 0;
      else if (ageValue >= 35 && ageValue <= 39) points += 2;
      else if (ageValue >= 40 && ageValue <= 44) points += 4;
      else if (ageValue >= 45 && ageValue <= 49) points += 5;
      else if (ageValue >= 50 && ageValue <= 54) points += 7;
      else if (ageValue >= 55 && ageValue <= 59) points += 8;
      else if (ageValue >= 60 && ageValue <= 64) points += 9;
      else if (ageValue >= 65 && ageValue <= 69) points += 10;
      else if (ageValue >= 70 && ageValue <= 74) points += 11;
      else if (ageValue >= 75) points += 12;
    }

    // HDL points
    const hdlValue = parseFloat(formData.hdl);
    if (hdlValue > 61.87) points += -2;
    else if (hdlValue >= 50.27 && hdlValue <= 61.87) points += -1;
    else if (hdlValue >= 46.4 && hdlValue <= 50.23) points += 0;
    else if (hdlValue >= 34.8 && hdlValue <= 46.37) points += 1;
    else if (hdlValue < 34.8) points += 2;

    // Total Cholesterol points
    const cholesterolValue = parseFloat(formData.totalCholesterol);
    if (cholesterolValue < 158.55) points += 0;
    else if (cholesterolValue >= 158.55 && cholesterolValue <= 201.05)
      points += formData.gender === 'male' ? 1 : 1;
    else if (cholesterolValue >= 201.08 && cholesterolValue <= 239.72)
      points += formData.gender === 'male' ? 2 : 3;
    else if (cholesterolValue >= 239.75 && cholesterolValue <= 278.42)
      points += formData.gender === 'male' ? 3 : 4;
    else if (cholesterolValue > 278.42)
      points += formData.gender === 'male' ? 4 : 5;

    // Systolic Pressure points
    const systolicValue = parseInt(formData.systolicPressure);
    if (formData.gender === 'male') {
      if (systolicValue < 120) points += formData.isTreated === 'true' ? -2 : 0;
      else if (systolicValue >= 120 && systolicValue <= 129)
        points += formData.isTreated === 'true' ? 0 : 2;
      else if (systolicValue >= 130 && systolicValue <= 139)
        points += formData.isTreated === 'true' ? 1 : 3;
      else if (systolicValue >= 140 && systolicValue <= 149)
        points += formData.isTreated === 'true' ? 2 : 4;
      else if (systolicValue >= 150 && systolicValue <= 159)
        points += formData.isTreated === 'true' ? 2 : 4;
      else if (systolicValue >= 160)
        points += formData.isTreated === 'true' ? 3 : 5;
    } else {
      if (systolicValue < 120)
        points += formData.isTreated === 'true' ? -3 : -1;
      else if (systolicValue >= 120 && systolicValue <= 129)
        points += formData.isTreated === 'true' ? 0 : 2;
      else if (systolicValue >= 130 && systolicValue <= 139)
        points += formData.isTreated === 'true' ? 1 : 3;
      else if (systolicValue >= 140 && systolicValue <= 149)
        points += formData.isTreated === 'true' ? 2 : 5;
      else if (systolicValue >= 150 && systolicValue <= 159)
        points += formData.isTreated === 'true' ? 4 : 6;
      else if (systolicValue >= 160)
        points += formData.isTreated === 'true' ? 5 : 7;
    }

    // Smoking points
    if (formData.isSmoker === 'true') {
      points += formData.gender === 'male' ? 4 : 3;
    }

    // Diabetes points
    if (formData.hasDiabetes === 'true') {
      points += formData.gender === 'male' ? 3 : 4;
    }

    return points;
  };

  const getRiskPercentage = points => {
    const riskTable = {
      male: {
        '-3': '<1',
        '-2': '1.1',
        '-1': '1.4',
        0: '1.6',
        1: '1.9',
        2: '2.3',
        3: '2.8',
        4: '3.3',
        5: '3.9',
        6: '4.7',
        7: '5.6',
        8: '6.7',
        9: '7.9',
        10: '9.4',
        11: '11.2',
        12: '13.2',
        13: '15.6',
        14: '18.4',
        15: '21.6',
        16: '25.3',
        17: '29.4',
        18: '>30',
        19: '>30',
        20: '>30',
        21: '>30',
      },
      female: {
        '-3': '<1',
        '-2': '<1',
        '-1': '1',
        0: '1.2',
        1: '1.5',
        2: '1.7',
        3: '2',
        4: '2.4',
        5: '2.8',
        6: '3.3',
        7: '3.9',
        8: '4.5',
        9: '5.3',
        10: '6.3',
        11: '7.3',
        12: '8.6',
        13: '10',
        14: '11.7',
        15: '13.7',
        16: '15.9',
        17: '18.5',
        18: '21.5',
        19: '24.8',
        20: '28.5',
        21: '>30',
      },
    };

    if (points <= -3) return formData.gender === 'male' ? '<1' : '<1';
    if (points >= 21) return '>30';

    return riskTable[formData.gender][points.toString()] || '>30';
  };

  const handleCalculate = () => {
    setError('');

    if (!validateInputs()) {
      setError('Por favor, complete todos los campos requeridos.');
      return;
    }

    const points = calculatePoints();
    const risk = getRiskPercentage(points);

    setResults({
      points: points,
      risk: risk,
    });
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box
        w={{ base: '100%', md: '25%' }}
        p={5}
        bg={bgColor}
        borderRight="1px solid"
        borderColor="gray.200"
      >
        <VStack spacing={6} align="stretch">
          <Image
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Logo"
            maxW="220px"
            mx="auto"
          />
          <Box bg="gray.100" p={5} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Calculadora de Riesgo Cardiovascular
            </Heading>
            <Text fontSize="sm">
              Complete los campos con la información del paciente para calcular
              el riesgo cardiovascular a 10 años según los criterios
              establecidos.
            </Text>
          </Box>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Button
              leftIcon={<FaArrowLeft />}
              onClick={() => navigate(-1)}
              size="md"
            >
              Volver
            </Button>
            <Heading as="h1" size="xl">
              Calculadora de Riesgo CDV
            </Heading>
            <Box width={24} />
          </Flex>

          <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
            <VStack spacing={6} align="stretch">
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <CustomSelect
                  label="Género"
                  value={formData.gender}
                  options={[
                    { value: 'male', label: 'Hombre' },
                    { value: 'female', label: 'Mujer' },
                  ]}
                  onChange={e =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                />
                <CustomInput
                  label="Edad"
                  value={formData.age}
                  onChange={e =>
                    setFormData({ ...formData, age: e.target.value })
                  }
                  placeholder="Edad"
                />
                <CustomInput
                  label="HDL (mg/dL)"
                  value={formData.hdl}
                  onChange={e =>
                    setFormData({ ...formData, hdl: e.target.value })
                  }
                  placeholder="HDL"
                />
                <CustomInput
                  label="Colesterol Total (mg/dL)"
                  value={formData.totalCholesterol}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      totalCholesterol: e.target.value,
                    })
                  }
                  placeholder="Colesterol Total"
                />
                <CustomInput
                  label="Presión Sistólica (mmHg)"
                  value={formData.systolicPressure}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      systolicPressure: e.target.value,
                    })
                  }
                  placeholder="Presión Sistólica"
                />
                <CustomSelect
                  label="¿Tratamiento para presión?"
                  value={formData.isTreated}
                  options={[
                    { value: 'false', label: 'No' },
                    { value: 'true', label: 'Sí' },
                  ]}
                  onChange={e =>
                    setFormData({ ...formData, isTreated: e.target.value })
                  }
                />
                <CustomSelect
                  label="¿Fumador?"
                  value={formData.isSmoker}
                  options={[
                    { value: 'false', label: 'No' },
                    { value: 'true', label: 'Sí' },
                  ]}
                  onChange={e =>
                    setFormData({ ...formData, isSmoker: e.target.value })
                  }
                />
                <CustomSelect
                  label="¿Diabetes?"
                  value={formData.hasDiabetes}
                  options={[
                    { value: 'false', label: 'No' },
                    { value: 'true', label: 'Sí' },
                  ]}
                  onChange={e =>
                    setFormData({ ...formData, hasDiabetes: e.target.value })
                  }
                />
              </SimpleGrid>

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle mr={2}>Error!</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <Button
                leftIcon={<FaCalculator />}
                colorScheme="blue"
                onClick={handleCalculate}
                size="lg"
                width="full"
              >
                Calcular Riesgo Cardiovascular
              </Button>

              {results && (
                <Box borderTop="1px" borderColor="gray.200" pt={4}>
                  <Heading size="md" mb={4}>
                    Resultados
                  </Heading>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <ResultItem label="Puntos Totales" value={results.points} />
                    <ResultItem
                      label="Riesgo CDV a 10 años"
                      value={`${results.risk}%`}
                    />
                  </SimpleGrid>
                </Box>
              )}
            </VStack>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
};

export default CardiovascularRiskCalculator;
