import React, { useRef, useState, useEffect } from 'react';
import { Box, Slider, SliderTrack, SliderFilledTrack, SliderThumb, IconButton, Flex, Text } from '@chakra-ui/react';
import { FaPlay, FaPause } from 'react-icons/fa';

const CustomAudioPlayer = ({ audioBlob }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      
      return () => URL.revokeObjectURL(audioUrl);
    }
  }, [audioBlob]);

  const handlePlayPause = () => {
    if (isLoaded) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
    setIsLoaded(true);
  };

  const handleSliderChange = (value) => {
    if (isLoaded && isFinite(value)) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  const formatTime = (time) => {
    if (!isFinite(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Box width="100%">
      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => setIsPlaying(false)}
      />
      <Flex align="center" mb={2}>
        <IconButton
          icon={isPlaying ? <FaPause /> : <FaPlay />}
          onClick={handlePlayPause}
          aria-label={isPlaying ? "Pause" : "Play"}
          mr={3}
          isDisabled={!isLoaded}
        />
        <Slider
          value={currentTime}
          max={duration}
          onChange={handleSliderChange}
          flex={1}
          isDisabled={!isLoaded}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Flex>
      <Flex justify="space-between">
        <Text fontSize="sm">{formatTime(currentTime)}</Text>
        <Text fontSize="sm">{formatTime(duration)}</Text>
      </Flex>
    </Box>
  );
};

export default CustomAudioPlayer;